import React from 'react';
import { FaNetworkWired, FaServer, FaHeadset } from 'react-icons/fa';
import { MdCloudQueue } from 'react-icons/md';

import { Layout, SEO, Hero, Cards } from '../../common';

interface Props {
  preview?: boolean;
  data: {
    title: string;
    subTitle: string;
    text: string;
    primaryColor: string;
    card1Title: string;
    card1Text: string;
    card2Title: string;
    card2Text: string;
    card3Title: string;
    card3Text: string;
    card4Title: string;
    card4Text: string;
  };
}

const ServicesPage: React.FC<Props> = ({ preview, data }) => {
  const {
    title,
    subTitle,
    text,
    primaryColor,
    card1Title,
    card1Text,
    card2Title,
    card2Text,
    card3Title,
    card3Text,
    card4Title,
    card4Text,
  } = data;

  const cardsData = [
    {
      title: card1Title,
      text: card1Text,
      children: <FaNetworkWired />,
    },
    {
      title: card2Title,
      text: card2Text,
      children: <FaServer />,
    },
    {
      title: card3Title,
      text: card3Text,
      children: <MdCloudQueue />,
    },
    {
      title: card4Title,
      text: card4Text,
      children: <FaHeadset />,
    },
  ];

  return (
    <Layout color={primaryColor} subnav preview={preview}>
      {!preview ? <SEO title="Services" /> : null}
      <Hero
        preview={preview}
        title={title}
        subTitle={subTitle}
        text={text}
        novideo
        badges={!preview}
      />
      <Cards cardsData={cardsData} color={primaryColor} />
    </Layout>
  );
};
export default ServicesPage;
