import React from 'react';
import { AiOutlineInteraction } from 'react-icons/ai';
import { FaSignOutAlt, FaLock, FaTools } from 'react-icons/fa';
import { FluidObject } from 'gatsby-image';
import { Layout, SEO, Hero, Cards } from '../../common';
import video from '../../../media/videos/cloud.mp4';

interface Props {
  preview?: boolean;
  data: {
    primaryColor: string;
    title: string;
    subTitle: string;
    text: string;
    fluid: FluidObject;
    card1Title: string;
    card1Text: string;
    card2Title: string;
    card2Text: string;
    card3Title: string;
    card3Text: string;
    card4Title: string;
    card4Text: string;
  };
}

const CloudPage: React.FC<Props> = ({ preview, data }) => {
  const {
    primaryColor,
    title,
    subTitle,
    text,
    fluid,
    card1Title,
    card1Text,
    card2Title,
    card2Text,
    card3Title,
    card3Text,
    card4Title,
    card4Text,
  } = data;

  const cardsData = [
    {
      title: card1Title,
      text: card1Text,
      children: <AiOutlineInteraction />,
    },
    {
      title: card2Title,
      text: card2Text,
      children: <FaSignOutAlt />,
    },
    {
      title: card3Title,
      text: card3Text,
      children: <FaLock />,
    },
    {
      title: card4Title,
      text: card4Text,
      children: <FaTools />,
    },
  ];

  return (
    <Layout color={primaryColor} subnav preview={preview}>
      {!preview ? <SEO title="Services" /> : null}
      <Hero
        preview={preview}
        title={title}
        subTitle={subTitle}
        text={text}
        small
        video={video as string}
        poster={fluid}
      />
      <Cards cardsData={cardsData} color={primaryColor} />
    </Layout>
  );
};

export default CloudPage;
